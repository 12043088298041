const state = {
    chatSidebar: true,
    chatRoom: localStorage.getItem('chatRoom') === null ? 'en' : localStorage.getItem('chatRoom'),
    chatMessages: {
        en: [],
        ru: [],
        tr: [],
        es: []
    }
}

const getters = {
     chatSidebar: state => state.chatSidebar,
     chatRoom: state => state.chatRoom,
     chatMessages: state => state.chatMessages
}

const mutations = {
    chat_set_sidebar(state, value) {
        state.chatSidebar = value;
    },
    chat_set_init(state, data) {
        state.chatMessages = data.messages;
    },
    chat_add_message(state, message) {
        state.chatMessages[message.room].push(message);
    },
    chat_remove_message(state, data) {
        state.chatMessages[data.room].splice(state.chatMessages[data.room].findIndex((element) => element._id.toString() === data.messageId.toString()), 1);
    },
    chat_remove_message_last(state, room) {
        state.chatMessages[room].splice(0, 1);
    },
    chat_set_room(state, room) {
        state.chatRoom = room;
    }
}

const actions = {
    chatSetSidebar({ commit }, value) {
        commit('chat_set_sidebar', value);
    },
    chatSetRoom({ getters, commit }, room) {
        localStorage.setItem('chatRoom', room);
        commit('chat_set_room', room);
    },
    chatSocketInit({ commit }, data) {
        commit('chat_set_init', data);
    },
    chatSocketMessage({ getters, commit }, data) {
        if(data.message.user.rank === 'system') {
            commit('chat_add_message', { ...data.message, room: 'en' });
            commit('chat_add_message', { ...data.message, room: 'ru' });
            commit('chat_add_message', { ...data.message, room: 'tr' });
            commit('chat_add_message', { ...data.message, room: 'es' });
        } else {
            commit('chat_add_message', data.message);

            if(getters.chatMessages[data.message.room].length > 50) {
                commit('chat_remove_message_last', data.message.room);
            }
        }
    },
    chatSocketTip({ getters, dispatch }, data) {
        getters.soundCash.volume = getters.soundVolume;
        getters.soundCash.play();

        dispatch('notificationShow', { type: 'success', message: 'You have received a '+parseFloat(data.transaction.amount / 1000).toFixed(2)+' tip from '+data.transaction.user.username+'.' });
    },
    chatSocketMute({ getters, commit }, data) {

    },
    chatSocketRemove({ getters, commit }, data) {
        const index = getters.chatMessages[data.room].findIndex((element) => element._id.toString() === data.messageId.toString());
        if(index !== -1) { commit('chat_remove_message', data); }
    },
    chatSendMessageSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'ChatMessage');

        getters.socketClient.emit('general:chat:sendMessage', data, (res) => {
            if(res.success !== true) {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    chatSendTipSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'ChatTip');

        getters.socketClient.emit('general:chat:sendTip', data, (res) => {
            if(res.success === true) {
                dispatch('modalsSetShow', null);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    chatSendRemoveSocket({ getters, commit, dispatch }, data) {
        if(getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'ChatRemove');

        getters.socketClient.emit('general:chat:sendRemove', data, (res) => {
            if(res.success === true) {
                dispatch('modalsSetShow', null);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const chat = {
    state,
    mutations,
    actions,
    getters
}

export default chat;
